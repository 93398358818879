<template>
	<div>
		<div class="content">
			<!-- 麵包屑 -->
			<div class="breadcrumb">
				<el-breadcrumb>
					<el-breadcrumb-item :to="{ path: '/diagnosis' }">诊断维修</el-breadcrumb-item>
					<!-- <el-breadcrumb-item :to="{ path: '/diagnosis' }"
            >维修师傅</el-breadcrumb-item
          > -->
					<el-breadcrumb-item>所有分类</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<!-- 排序 -->
			<div class="sort flex ai-center">
				<div class="btn cursor" :class="{ active: currentIndex == index }" v-for="(item, index) in sortList" :key="index" @click="chooseSort(index)">{{ item.value }}</div>
				<div :class="{ active1: currentIndex == '3' }" @click="chooseCity(3)">
					<el-dropdown trigger="click" @command="getCityType">
						<span class="el-dropdown-link">常住地区</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-for="(item, index) in cityList" :key="index" :command="item">{{ item }}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<div v-if="expertList && expertList.length">
				<!-- 列表 -->
				<div class="recommended">
					<div class="recomoutside cursorPointer" v-for="(item, index) in expertList" :key="index">
						<div class="recomBlock" @click="toexpertDetail(item.id)">
							<div class="img"><img :src="photoURL + `${item.head}`" alt="" /></div>
							<div>
								<div class="rate"><el-rate v-model="item.score" disabled show-score :colors="colors" text-color="#A4A9B0" score-template="{value}"></el-rate></div>
								<div class="Recomword1">{{ item.name }}</div>
								<div class="Recomword2">诊断专家·{{ item.city }}</div>
								<div>
									<span class="Recomword3">¥ {{ item.priceMin }}</span>
									/次
								</div>
								<div class="Recomword4">{{ item.introduce }}</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 分页 
          hide-on-single-page-->
				<div class="globalpPagination">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="current"
						:page-size="size"
						layout=" prev, pager, next,sizes, jumper"
						:total="total"
					></el-pagination>
				</div>
			</div>
			<div class="empty flex jc-center ai-center" v-else>
				<div class="flex flex-col jc-center ai-center">
					<img src="../img/expertEmpty.png" />
					<span>暂无相关内容</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Bus from '@/bus/index.js';
export default {
	data() {
		return {
			sortList: [{ value: '默认', index: 0 }, { value: '好评优先', index: 1 }, { value: '接单量', index: 2 }],
			cityIndex: 0,
			keyword: '',
			skillType: 1,
			cityList: [],
			currentIndex: 0,
			expertList: [],
			current: 1,
			total: 0,
			size: 16,
			cityVal: '',
			colors: ['#FF9F0A', '#FF9F0A', '#FF9F0A']
		};
	},
	methods: {
		chooseSort(val) {
			this.currentIndex = val;
			this.getExpertList();
		},
		chooseCity(val) {
			this.currentIndex = val;
			this.cityIndex = val;
		},
		getCityType(e) {
			this.cityVal = e;
			this.getExpertList();
			this.cityVal = '';
			console.log(e, 'kkkk');
		},
		// 获取地区列表
		getcityList() {
			this.$get('/shop-api/mall/expert/city/list').then(data => {
				if (data.code == 1000) {
					this.cityList = data.data;
				}
			});
		},
		// 获取专家列表
		getExpertList() {
			this.$get('/shop-api/mall/expert/list', {
				city: this.cityVal,
				searchType: this.currentIndex,
				skillType: this.skillType,
				current: this.current,
				size: this.size,
				keyword: this.keyword
			}).then(data => {
				if (data.code == 1000) {
					this.expertList = data.data.records;
					this.total = data.data.total;
				}
			});
		},
		toexpertDetail(id) {
			this.$router.push({
				path: '/diagnosisDetails',
				query: { id, num: '1' }
			});
		},
		// 每页显示的条数
		handleSizeChange(val) {
			this.size = val;
			this.getExpertList();
		},
		handleCurrentChange(val) {
			this.current = val;
			this.getExpertList();
		}
	},
	watch: {
		'$route.query.id'(newVal) {
			this.skillType = newVal;
			this.getExpertList();
		},
		$route: {
			handler(val) {
				if (val.name == 'diagnosisExpert'){
					this.Bus.$emit("headerSearch2",{activeMenu:2})
					if(val.query.search){
						this.keyword = val.query.search;
					}
					if(val.query.id){
						this.skillType = val.query.id;
					}
				} 
				this.getExpertList();
			},
			immediate: true
		}
	},
	mounted() {
		this.$nextTick(() => {
			Bus.$on('headerSearch', keyword => {
				this.keyword = keyword;
				this.getExpertList(keyword);
			});
		});
		this.getExpertList();
		this.getcityList();
	},
	beforeDestroy() {
		Bus.$off('headerSearch');
	}
};
</script>

<style lang="scss" scoped>
* {
	letter-spacing: 1px !important;
	.content {
		.el-button {
			height: 40px;
		}
		background-color: #f5f5f5;
		width: 1440px;
		margin: 0 auto;
		.breadcrumb {
			height: 40px;
			color: #8c8c8c;
			letter-spacing: 1px;
			padding-left: 10px;
			background-color: #efeff4;
			.el-breadcrumb {
				display: flex;
				align-items: center;
				height: 40px;
			}
		}
		.sort {
			width: 1420px;
			margin: 0 auto;
			border-radius: 5px;
			margin-top: 15px;
			padding-left: 5px;
			background-color: #ffffff;
			height: 64px;
			cursor: pointer;
			.btn {
				font-size: 16px;
				font-weight: 500;
				line-height: 40px;
				text-align: center;
				width: 100px;
				height: 40px;
				margin-right: 10px;
				background: #e5e5e5;
				border-radius: 4px;
			}
			.active {
				color: white;
				background-color: #4a99f6;
			}
			.active1 {
				// cursor: pointer;
				.el-dropdown {
					color: #fff;
					background: #4a99f6;
				}
			}
		}
		.recommended {
			display: flex;
			flex-wrap: wrap;
			margin: 0 auto;
			.recomoutside {
				display: flex;
				justify-content: center;
				align-items: center;
				.recomBlock {
					border-radius: 5px;
					background-color: white;
					width: 340px;
					margin: 10px;
					text-align: center;
					.img {
						width: 143px;
						height: 143px;
						margin: 25px auto;
						border-radius: 50%;
						img {
							object-fit: cover;
							width: 100%;
							height: 100%;
							border-radius: 50%;
						}
					}
					.Recomword1 {
						color: #333;
						font-size: 16px;
						font-weight: 600;
						margin: 16px 0;
					}
					.Recomword2 {
						color: #888888;
						font-size: 14px;
						margin: 10px 0 12px;
					}
					.Recomword3 {
						font-size: 20px;
						font-weight: 600;
						color: #4a99f6;
					}
					.Recomword4 {
						margin: 30px auto 40px;
						width: 300px;
						height: 42px;
						font-size: 14px;
						color: #888888;
						text-align: justify;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
					.rate {
						margin: 12px 0;
					}
					&:hover {
						box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
					}
				}
			}
		}
		.empty {
			color: #8c8c8c;
			min-height: 700px;
		}
	}
}
.el-dropdown {
	font-size: 16px;
	font-weight: 500;
	line-height: 40px;
	color: #262626;
	text-align: center;
	width: 100px;
	height: 40px;
	margin-right: 10px;
	background: #e5e5e5;
	border-radius: 4px;
}
.el-rate__icon {
	font-size: 20px;
}
</style>
